  .products__nav{
    display: flex;
    justify-content: center;
    list-style: none;
    padding-left:0;
    width: 100%;
    text-align: center;
    margin:0;
    border-right: 1px solid #171717;
    border-left: 1px solid #171717;
    li{
        width: 100%;
    }
    &__item{
      font-size:20px;
      padding: 10px 0;
      display: block;
      width: 100%;
      color:white;
      text-decoration: none;
    }
    &__item:hover{
      
    }
  }
  
  .active{
    background: rgba(0,163,239,1);
  }

  @media screen and (max-width:992px){
    .products__nav{
      display: flex;
      width: 100%;
      padding-right:0;
      &__item{
        padding:15px 0px;
        text-align:center;
      }
    }
    hr{
      display:none;
    }
  }
  @media screen and (max-width:650px){
    .products__nav{
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-right:0;
      margin-bottom: 0px;
      &__item{
        padding:10px 0px;
        text-align:center;
      }
    }
    hr{
      display:none;
    }
  }