.products__table{
    &__header{
      display: flex;
      margin-bottom:5px;
      color:white;
      img{
        width: 300px!important;
        align-self: center;
      }
      p{
        
        font-size:18px;
        padding-bottom:100px;
    }
    h2{
      padding-top:100px;
      font-size:36px;
      margin-bottom:40px;
    }
    }
  }
  
  hr {
    color: black;
    background-color: black;  
  }
  
  

  @media screen and (max-width:650px){
 .products__table{
  &__header{
    flex-direction: column;
    p{
      font-size:18px;
    }
    img{
      margin-top:10px;
    }
  }
  
 }
  }
  