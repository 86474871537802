
 .offer{
    color:white;
    padding-top:25vh;
    h1{
        margin: 0;
        font-size: 60px;
        font-weight: 600;
    }
    p{
        margin: 44px 0 0 0;
        font-size: 30px;
    }
    a{
        text-decoration: none;
        color:white;
        display:block;
        padding:20px;
        font-size: 24px;
        margin-top:71px;
        background-color:rgba(0,163,239,.62);
        width: 222px;
        text-align: center;
        transition:all .44s ease;
    }
    a:hover{
        background-color:rgba(0,163,239,1);
    }

}
@media screen and (max-width: 650px){
    .offer{
        padding-top:17vh;
        h1{
            font-size:32px;
        }
        p{
            
            font-size:17px;
        }
    }
}
@media screen and (max-height: 768px) and (min-width: 1572px){
    .offer{
        padding-top:20vh;
        h1{
            font-size:42px;
        }
        p{
            font-size:28px;
        }
    }
}

@media screen and (max-height: 435px) and (max-width: 992px){
  .offer{
      padding-top:30px;
      h1{
          font-size:32px;
      }
      p{
          font-size:18px;
          margin-top:15px;
      }
      a{
          margin-top:20px;
      }
  }
}
@media screen and (max-width: 650px) and (max-height:360px){
    .offer{
        padding-top:5px;
        h1{
            font-size:28px;
        }
        p{
        margin-top:10px;
        font-size:18px;  
        }
        a{
            font-size:18px;
            
            margin-top:10px;
        }
    }
  }

@media screen and (max-width: 320px){
    .offer{
        h1{
            font-size:28px;
        }
        p{
            font-size:16px;
            margin-top:25px;
        }
        a{
            margin-top:50px;
        }
    }
}
