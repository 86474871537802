.logo{
    display: flex;
    flex-direction: row;
    padding-right: 43px;
    &__link{
        color:white;
        text-decoration: none;
    }
}

.logo:hover ~ .logo__text{
    font-size: 72px;
}

.logo__pic{
    background-image: url('../../i/logo.png');
    width: 50px;
    height: 50px;
    margin-right: 13px;
}

.logo__text{
    font-size: 36px;
    position:relative;
}

.line{
    left:255px;
    top: 16px;
    position: absolute;
    height: 25px;
    width: 1px;
    background-color: #fff;
}

@media screen and (max-width: 1572px){
    .logo{
        &__text{
            font-size:24px;
            margin-top:9px;
        };
    }
    .line{
        left:190px;
        top:7px;
    }
}
@media screen and (max-width: 1270px){
    .logo{
        padding-right:0;
    }
    .line{
        left:150px;
    }
}

@media screen and (max-width: 992px){
    .header{
        justify-content: space-between;
    }
    .line{
        display: none;
    }
}

@media screen and (max-width: 650px){
    .logo{
        margin-left: 25px;
    }
}

@media screen and (max-width: 320px){
    .logo{
        margin-left:7px;
    }
}