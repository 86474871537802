.header{
    display: flex;
    justify-content: flex-start;
    padding-top:25px;
    padding-bottom:15px;
    color:white;
}

.drawer_toggle{
    display: none;
}

.menu{
    font-size:24px;
    margin: 13px 0 0 70px;
    padding: 0;
    padding-bottom:15px;
    list-style-type:none;
    display:flex;
    .active{
        border-bottom:2px solid rgba(0,163,239,.62);
        pointer-events: none;
        
    }
    a.menu__item{
        color:white;
        text-decoration: none;
        margin-right:84px;
    }
    
    a.menu__item:hover{
        border-bottom:2px solid rgba(0,163,239,.62);
    }
    
}
.sticky{
    position: fixed;
    top: 0;
    width: 100%
}
.download{
    color:white;
    text-decoration: none;
    background:rgba(0,163,239,.62);
    font-size:21px;
    padding:16px 20px 0 20px;
    transition:all .44s ease;
    margin-right:65px;
}
.download:hover{
    background: rgba(0,163,239,1);
}
.contacts{
    font-size:16px;
    margin-top:6px;
}

.make_call{
    display: none;
}


@media screen and (max-width: 1572px){

    .menu{
        font-size:18px;
        margin: 15px 0 0 70px;
        a{
            margin-right:50px;
        }

    }
    .contacts{
        font-size:13px;
    }

    .download{
        padding:13px 20px 0 20px;
        margin-right:20px;
    }
    .contacts{
        margin-top:10px;
    }
  }
  @media screen and (max-width: 1270px){
    .menu{
        margin-left:40px;
        a.menu__item{
            margin-right:25px;
        }
    }
    .download{
        padding:15px 20px 0 20px;
        font-size:18px;
    }
  }

  @media screen and (max-width: 992px){
    .header{
        justify-content: space-between;
    }
    .download{
        display: none;
    }
    .drawer_toggle{
        margin-top:14px;
        display:block;
    }
    .menu{
        display:none;
    }
    .contacts{
        margin-top:2px;
        font-size: 18px;
    }
  }

  @media screen and (max-width: 650px){
    .header{
        justify-content: space-between;
    }
    .contacts{
        display: none;
    }
    .make_call{ 
        color:white;
        display:block;
        margin-left:30px;
        padding:15px;
        background-color:rgba(0,163,239,.8);
    }
  }
  @media screen and (max-width: 320px){
      .make_call{
          margin-left:5px;
      }
  }

