.footer{
    display: flex;
    justify-content: space-between;
    padding-top:25px;
    padding-bottom: 20px;
    color:#808080;
}

.logo{
    display: flex;
    flex-direction: row;
    padding-right: 43px;
    &__link{
        color:white;
        text-decoration: none;
    }
}

.logo__pic{
    background-image: url('../../i/logo.png');
    width: 50px;
    height: 50px;
    margin-right: 13px;
}

.logo__text{
    font-size: 36px;
    position:relative;
}

.line{
    left:255px;
    top: 16px;
    position: absolute;
    height: 25px;
    width: 1px;
    background-color: #fff;
}

.information{
    margin-top:15px;
    display: flex;
}

.author{
    font-size:12px;
    margin-top:8px;
    a{
        color:#808080;
    }
}

.imgs{
    display: flex;
    margin-top: -7px;
    margin-left:15px;
}

.imgs__item{
    height: 35px;
    margin-right: 15px;
    filter: gray; /* IE5+ */
    -webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
    -webkit-transition: all .2s ease-in-out;
}

.imgs__item:hover{
    filter: none;
      -webkit-filter: grayscale(0);
      -webkit-transform: scale(1);
}

.contacts{
    font-size:16px;
    margin-top:4px;
}


@media screen and (max-width: 1572px){
    .logo{
        &__text{
            font-size:24px;
            margin-top:9px;
        };
    }

.footer{
    text-align: center;
}

.line{
    left:155px;
    top:6px;
}

.copyright{
    margin-top:-10px;
}

.author{
    margin-top:0;
}

.contacts{
    margin-top:0;
    font-size:12px;
}
}


@media screen and (max-width: 1572px){
    .copyright{
        margin-top:0px;
    }
    .author,.contacts{
        margin-top:7px;
    }
}

@media screen and (max-width: 1270px){
    .copyright{
        display: none;
    }
}

@media screen and (max-width: 992px){
    .imgs{
        display: none;
    }
    .line{
        left:180px;
    }
    .author,.contacts{
        margin-top:10px;
    }
}
@media screen and (max-width: 650px){
    .footer{
        justify-content: center;
    }
    .logo{
        display: none;
    }
    .contacts,.author{
        margin-top:0;
        text-align: left;
    }
    .author{
        margin-right:10px;
        text-align: center;
    }
    .line{
        display:none;
    }
}

@media screen and (max-width: 320px){
    .contacts{
        text-align: center;
    }
    .author{
        margin-right:0;
    }
}