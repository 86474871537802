.container{
    width: 1572px;
    margin: 0 auto;
  }
  
  @media screen and (min-height: 1024px){
    .offer__bg{
      height: 1024px;
    }
  }
  
  @media screen and (max-width: 1572px){
    .container{
      max-width: 1270px;
    }
  }
  
  @media screen and (max-width: 1270px){
    .container{
      max-width: 992px;
    }
  }
  @media screen and (max-width: 992px){
    .container{
      max-width: 650px;
    }
  }
  @media screen and (max-width: 650px){
    .container{
      width: 90%;
      overflow: hidden
    }
  }
  @media screen and (max-width: 320px){
    .container{
      width: 90%;
      overflow: hidden
    }
  }
  @media screen and (max-width: 650px) and (max-height:360px){
    .container{
      max-width: 500px;
    }
  }