.products{
    text-align:center;
    color:white;
    padding-bottom: 189px;
    h2{
        padding-top: 84px;
        padding-bottom: 59px;
    }
    &__list{
        list-style: none;
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0;
        position: relative;
    }
    .products__link {
        position: relative;
        background: #000;
      }
      
      .products__image {
        opacity: 1;
        display: block;
        width: 100%;
        height: auto;
        transition: .22s ease;
        backface-visibility: hidden;
      }
      
      .products__overlay {
        transition: .22s ease;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;
      }
      
      .products__link:hover .products__image {
        opacity: 0.3;
      }
      
      .products__link:hover .products__overlay {
        opacity: 1;
      }
      
      .products__button {
        text-decoration: none;
        background-color:rgba(0,163,239,.62);
        color: white;
        font-size: 16px;
        padding: 16px 32px;
        transition:all .22s ease;
      }
      .products__button:hover{
        background-color:rgba(0,163,239,1);
      }
    li{
        color:black;
        width: 360px;
        background: #fff;
        z-index:5;
        margin-left: 20px;
        padding: 5px;
        h3{
            font-size:24px;
            margin-top:20px;
            margin-bottom: 20px;
        }
    }
    .white__line{
        top:465px;
        left:47.8%;
        position: absolute;
        height: 1px;
        width: 85px;
        background-color: #fff;
    }
}

@media screen and (max-width: 1572px){
    .products{
        &__link{

            img{
                width: 240px;
                height: 217px;
            }
            h3{
                margin: 10px 0 10px 0;
            }
        }
        .white__line{
            top:385px;
            left:47%;
        }
    }
  }

  @media screen and (max-width: 1170px){
    .products{
        &__list{
        }
        li{
            margin-left:1px;
            img{
                width: 220px;
                
            }
        }
        .white__line{
            top:360px;
            left:45.8%;
        }
    }
  }

  @media screen and (max-width: 992px){
    .products{
        padding-bottom:100px;
        &__list{
            flex-wrap:wrap;
        }
        li{
            margin:5px;
            width: 310px;
            img{
                width: 300px;
            }
        }
        .white__line{
            display: none;
        }
    }
    .products__link .products__image {
        opacity: 0.5;
    }
      
    .products__link .products__overlay {
        opacity: 1;
    }  
  }

  @media screen and (max-width:650px){
      .products{
          &__list{
            padding-bottom:40px;
          }
          h2{
            padding-top:80px;
            padding-bottom:80px;
          }
      }
  }

  @media screen and (max-width: 320px){
    .products{
        li{
            img{
                width: 280px;
            }
        }
    }
}

