.blue__bg{
  background-color: #00a3ef;
}

.black__bg{
  background-color: #171717;
}

.darker__bg{
  
  background-color: #1E1E1E;
}

.products__info{
  display: flex;
  flex-direction: column;
}


footer{
  background: #171717;
}
.products__table{
  width: 100%;
}

@media screen and (max-width:992px){
  .products__info{
    flex-direction: column;
  }
}

