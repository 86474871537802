
  // ../../i/offer-bg.png
  //center no-repeat / cover
  
  .blue__bg{
    background-color: #00a3ef;
  }

  .black__bg{
      background-color: #1E1E1E;
  }
  
  h2{
    font-size:36px;
    margin-bottom:40px;
  }
  
  footer{
    background: #1E1E1E;
  }

  .contacts_data__container{
    margin:100px 0 100px 0;
    display: flex;
    justify-content: space-between;
  }
  .contacts_data{
    margin:0 50px 50px 0;
  }

  .contacts_map{
    flex-grow: 2;
    
  }
  
  @media screen and (max-width:992px){
    .contacts_data__container{
      flex-direction: column;
    }
    .contacts_map{
      pointer-events:none
    }
  }
  
  