.side_drawer{
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    color:white;
    
    position: fixed;
    top:0;
    left: 0;
    width: 70%;
    max-width: 350px;
    z-index:200;
    transform: translateX(-110%);
    transition: transform 0.22s ease-out;
    
    nav{
        text-align: center;
        height: 100%;
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-left:0;
        .active{
            background: rgba(0,163,239,1);
            color:white;
        }
    }
    a{
        color:white;
        text-decoration: none;
        font-size:24px;
        width: 100%;
        padding:50px 0 50px 0;
    }
    .download{

    }
    a:hover, a:active{
        background: rgba(0,163,239,1);
    }
}

.open{
    transform: translateX(0);
}

@media screen and (max-width: 650px){
    .side_drawer{
        a{
            padding: 20px 0 20px 0;
        }
    }
}
