table{
    width: 100%;
    border-collapse: collapse;
    transition: all 1s ease;
    margin-bottom:100px;
    table-layout: fixed;
    th{
        background-color: #f3f4f8;
        padding:15px;
        font-size:18px;
    }
    td{
        padding:10px;
        word-wrap:break-word;
        i{
            color:#ccc;
            margin-left:7px;
            transition: all .22s ease;
            position: relative;
            display: inline-block;

            .tooltiptext{
                font-weight: 300;
                visibility: hidden;
                width: 120px;
                background-color: black;
                color: #fff;
                text-align: center;
                padding: 5px 0;
                border-radius: 6px;
                position: absolute;
                bottom: 120%;
                left: 50%; 
                margin-left: -60px;
                z-index: 1;
                font-size: 16px;
            }
            .tooltiptext::after {
                content: " ";
                position: absolute;
                top: 100%; 
                left: 50%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: black transparent transparent transparent;
              }
        }
        i:hover{
            cursor: pointer;
            color:black;
            .tooltiptext{
                visibility: visible;
            }
        }

    }
    tr{
        padding: 10px;
        border-bottom:1px solid #f3f4f8;
    }
    .skeleton{
        width: 100%;
    }
    
}
@media screen and (max-width:650px){
    h3{
        font-size:24px;
    }
    table{
        font-size:12px;
        th{
            font-size:12px;
        }
        td{
            line-height: 100%;
        }
    }

}