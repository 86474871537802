body{
  color:#171717;
}
.offer__bg{
  background-image: url(../../../i/offer-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}
// ../../i/offer-bg.png
//center no-repeat / cover

.blue__bg{
  background-color: #00a3ef;
  background-image: url(../../../i/products-bg.png)
}

h2{
  font-size:36px;
  margin: 0;
}

footer{
  background: #171717;
}

@media screen and (max-height: 435px) and (max-width: 992px){
  
}
