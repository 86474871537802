.advantages{
    text-align: center;
    h2{
        padding-top:138px;
        padding-bottom:98px;
    }
    h3{
        padding-top:69px;
        padding-bottom:45px;
        font-size:24px;
        margin: 0;
    }
    p{
        color:#b2b2b2;
        font-weight: 300;
    }
    ul{
        padding-left:0;
        list-style: none;
        display: flex;
        justify-content: space-around;
        padding-bottom:140px;
    }
    .mid__block{
        position: relative;
    }
    .blue__line{
        top:465px;
        left:37%;
        position: absolute;
        height: 1px;
        width: 85px;
        background-color: #00a3ef;
    }
    li{
        width: 290px;
    }
    .durable{
        background-image: url(../../i/advantages/durable.png);
        margin:0 auto;
        width: 142px;
        height: 142px;
    }
    .delivery{
        background-image: url(../../i/advantages/delivery.png);
        margin:0 auto;
        width: 138px;
        height: 142px;
    }
    .price{
        background-image: url(../../i/advantages/price.png);
        margin:0 auto;
        width: 137px;
        height: 142px;
    }
}

@media screen and (max-width: 992px){
    .advantages{
        ul{
            flex-direction: column;
            align-items: center;
            padding-bottom:80px;
        }
        li{
            width: 400px;
            margin-bottom: 50px;
        }
        .durable, .delivery,.price{
            background-repeat: no-repeat;
            background-size: cover;
            width: 200px;
            height: 200px;
        }
        h2{
            font-size:40px;
        }
        h3{
            font-size: 32px;
        }
        p{
            font-size:24px;
        }
        .blue__line{
            display: none;
        }
    }
}

@media screen and (max-width: 650px){
    .advantages{
        ul{
            padding-bottom:40px;
        }
        li{
            width: 320px;
            margin-bottom: 50px;
        }
        h2{
            
            padding-top:80px;
            padding-bottom:80px;
        }
        h3{
            font-size:28px;
        }
        p{
            font-size:16px;

        }
        .durable,.delivery,.price{
            width: 150px;
            height: 150px;
        }
    }
}

@media screen and (max-width: 320px){
    .advantages{
        h2{
            font-size:26px;
        }
        h3{
            font-size:21px;
            padding-top:30px;
            padding-bottom:10px;
        }
    }
}